<template>
<div>
  <b-card-group deck>
    <b-card
    
      title="Client's Transaction"
    >
          <downloadexcel
	class   = "btn btn-default"
	:data   = "items"
	name    = "Transaction.xls">
Export
</downloadexcel>
  <TableView
  :headers="columns"
  :rows="items"      
  :sort="sort1"
>
<!-- <template slot="table-row" slot-scope="props">
    <span v-if="props.columns.key == 'actions'">
      <a :href="`/user/${props.row.id}/edit`">Edit</a>
    </span>
    <span v-else>
      {{props.formattedRow[props.columns.key]}}
    </span>
  </template> -->
  <template v-slot:items="{ row }">
    <td>{{ row.id }}</td>
    <td>{{ row.description }}</td>
    <td>{{ row.amount }}</td>   
    <td>{{ row.type }}</td>                
    <TablePagination></TablePagination>        
  </template>
  <template v-slot:no-data>
    <span>No data</span>
  </template>
</TableView>
    </b-card>  
  </b-card-group>
</div>
</template>

<script>
import downloadexcel from "vue-json-excel";
import TableView from "@/components/TableView.vue"
import TablePagination from "@/components/TablePagination.vue"
export default {
  components:{
    TableView,
    TablePagination,
  downloadexcel
  },
   data(){
    return{
      columns: [
        // {
        // key: 'action',
        //     label: 'Actions',
        // },
        {
        key: 'id',
            label: 'ID',
        },
        {
        key: 'description',
        label: 'Transaction Details',
        },
        {
        key: 'type',
        label: 'Transaction Type',
        },
        {
        key: 'amount',
        label: 'Amount (NGN)',
        },
        
      ], // column data
      items: [

        {
          id: 1,
          description: "Wallet Top Up",
          type: "Cr",
          amount: "20,000.00",
          
        },
        {
          id: 2,
         description: "Consultation fee paid to Dr. Ahmed Ojo",
          type: "Dr",
          amount: "10,000.00",
          
        },
        {
          id: 3,
         description: "Vaccination Payment ",
          type: "Dr",
          amount: "5,000.00",
          
        },
        {
          id: 4,
         description: "Wallet Top Up",
          type: "Cr",
          amount: "15,000.00",
          
        },
        
      ], // tabular data
      details:[], // table details
      sort1:{
        field: 'description',
        order: 'asc'
      },    
      pagination:{
        itemsPerPage: 3,
        visualStyle: 'select'
      }
    }    
  },
  headers: {
  type: Array,
  default() {
    return []
  },
  required: true
},   
rows: {
  type: Array,
  default() {
    return []
  }, 
  required: true
},        
sort: {
  type: Object,
  default() {
    return {}
  }
},
//  page : Number,
// totalPages: Number,
//  paginationOptions: Object,
pagination: {
  type: Object,
  default() {
    return {}
  }
},
cssStyle: {
  type: String,
  default: 'ozn'
}

}
</script>
<style scoped>
.btn.btn-default {
      color: #1313ea;
    background-color: #F3F6F9;
    border-color: #F3F6F9;
    float: right;
}
</style>